import { Article, ArticleIdentifier, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useArticleByArticleNumberWithOptionalVehicle(request: ArticleIdentifier | undefined, language?: string) {
    const response = useArticlesByArticleNumbersWithOptionalVehicle(request ? [request] : undefined, language)
    return response.length ? response[0] : undefined
}

export function useArticlesByArticleNumbersWithOptionalVehicle(requests: Array<ArticleIdentifier> | undefined, language?: string) {
    const enabled = !!requests?.length

    const result = useQuery({
        queryKey: ["parts_useArticlesByArticleNumbersWithOptionalVehicle", requests, language],
        queryFn: () => {
            return Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicle).subscribe(requests).load()
        },
        enabled,
        staleTime: 4 * 60 * 60 * 1000, // 4 hours
    })

    return result.data ?? []
}
