import * as React from "react"
import { styled } from "@mui/material"
import { LEFT_ROW_INDICATOR, ZINDEX_BASE } from "../configs"

type ExtendedElementProps = {
    active?: boolean
    colCount: number
    rowIndicatorColor?: string
}

type ExtendedElementType = React.FC<ExtendedElementProps & React.HTMLAttributes<HTMLSpanElement>>

function shouldForwardProp(prop: PropertyKey) {
    return !["theme", "colCount", "active", "rowIndicatorColor"].includes(prop as string)
}

export const ExtendedElement: ExtendedElementType = styled("span", { shouldForwardProp })<ExtendedElementProps>((props) => {
    const { theme, colCount, active, rowIndicatorColor } = props
    const elementListOverwrites = theme.overwrites?.components?.elementList ?? {}
    const overwritesBorderLeftWidth = elementListOverwrites?.backgroundColor?.borderLeftWidth
    const borderLeftWidth = overwritesBorderLeftWidth || LEFT_ROW_INDICATOR

    return {
        zIndex: ZINDEX_BASE,
        gridColumnStart: 1,
        gridColumnEnd: `span ${colCount}`,
        padding: theme.spacing(1.2, 2, 0, 2),
        display: "flex",
        borderLeft: `${active ? 1 : borderLeftWidth}px solid ${rowIndicatorColor || theme.palette.grey[200]}`,
        borderRight: `1px solid ${theme.palette.grey[200]}`,
    }
})
