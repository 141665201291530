import { MouseEvent, useCallback, useMemo, useState } from "react"
import { MenuList, Popover } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { Watchlist } from "../../Watchlist"
import { IconButton } from "../../IconButton"
import { Tooltip } from "../../../../generics/tooltip"
import { Icon } from "../../../../generics/Icons"
import { ActionMenuItem } from "./ActionMenuItem"

export function ExpandedActionsContent() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const { hasWatchList, highlightWatchlistButton } = useDefaultArticleItemState((x) => x.options)
    const [moreMenuEl, setMoreMenuEl] = useState<HTMLButtonElement>()

    const buttonList = useMemo(() => articleActions.filter(({ handler, area }) => area === "RIGHT_ICONS" && !!handler), [articleActions])
    const moreList = useMemo(() => articleActions.filter(({ handler, area }) => area === "RIGHT_MENU" && !!handler), [articleActions])

    const handleClickMore = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setMoreMenuEl(e.currentTarget)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleFurtherOptions)
    }, [])

    const handleCloseMore = useCallback(() => {
        setMoreMenuEl(undefined)
    }, [])

    return (
        <>
            {buttonList.map(({ id, text, tooltip: title, icon, handler, isHighlight }) => (
                <Tooltip key={id} title={title ?? text}>
                    <IconButton key={id} onClick={handler} hoverEffect disableRipple>
                        <Icon name={icon} color={isHighlight ? "primary" : undefined} />
                    </IconButton>
                </Tooltip>
            ))}

            {hasWatchList && !highlightWatchlistButton && <Watchlist />}

            {!!moreList.length && (
                <>
                    <IconButton onClick={handleClickMore} hoverEffect disableRipple>
                        <Icon name="ellipsis" />
                    </IconButton>
                    <Popover
                        anchorEl={moreMenuEl}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        open={!!moreMenuEl}
                        onClose={handleCloseMore}
                    >
                        <MenuList variant="menu">
                            {moreList.map((action) => (
                                <ActionMenuItem handleCloseMore={handleCloseMore} action={action} key={action.id} />
                            ))}
                        </MenuList>
                    </Popover>
                </>
            )}
        </>
    )
}
