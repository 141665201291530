import { useMemo } from "react"
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ErpIcons } from "../ErpIcons"
import { ButtonAction } from "./ButtonAction"
import { FoundBy } from "./FoundBy"
import { IconAction } from "./IconAction"
import { AdditionalInformation } from "./AdditionalInformation"
import { Tooltip } from "../../../generics/tooltip"

export function CenterActions() {
    const articleActions = useDefaultArticleItemState((x) => x.articleActions)
    const articleListExpanded = useDefaultArticleItemState((x) => x.expanded)

    const { breakpoints } = useTheme()
    const isHighResolution = useMediaQuery(breakpoints.up("xl"))

    const iconActionList = useMemo(() => articleActions.filter((x) => x.area === "CENTER_ICONS"), [articleActions])
    const buttonActionList = useMemo(() => articleActions.filter((x) => x.area === "CENTER_BUTTONS"), [articleActions])

    return (
        <Stack direction="row" justifyContent="flex-end" ml={2} flex={1} flexWrap="wrap">
            <Stack direction="row" spacing={1} gap="10px" alignItems="center">
                <ErpIcons position="articleInfo" />
                <FoundBy />
                {iconActionList.map((action) => (
                    <IconAction key={action.id} action={action} />
                ))}
                <AdditionalInformation />
                {buttonActionList.map((action) => (
                    <ButtonAction key={action.id} action={action} hideText={!isHighResolution || !articleListExpanded} />
                ))}
            </Stack>
        </Stack>
    )
}
