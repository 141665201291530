import { Box, styled } from "@mui/material"
import { BonusSystemParams } from "@tm/models"
import { Typography } from "../../../generics/typographie"

const BonusPointBox = styled(Box)(({ theme }) => ({
    border: "solid 1px #085cf0",
    borderRadius: theme.radius?.default,
    padding: theme.spacing(0.25, 1),
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: "#085cf0",
    marginBottom: "8px !important",
}))

const BonusPointsText = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText("#085cf0"),
    minWidth: "3em",
    lineHeight: 1,
    fontSize: theme.font?.textSize.s,
    small: {
        fontSize: theme.font?.textSize.xs,
    },
}))

type BonusPointProps = {
    bonus: BonusSystemParams
    label?: string
    tooltipLabel?: string
}

export default function BonusPoint({ bonus, label, tooltipLabel }: BonusPointProps) {
    return (
        <BonusPointBox title={tooltipLabel ?? bonus.name}>
            <BonusPointsText>
                <strong>{bonus.value?.toLocaleString()}</strong>
                {label !== undefined && (
                    <>
                        <br />
                        {label}
                    </>
                )}
            </BonusPointsText>
        </BonusPointBox>
    )
}
