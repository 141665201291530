import { forwardRef, PropsWithChildren, useEffect, useState } from "react"
import { useLocalStorage } from "@tm/utils"
import { Box } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Dialog, TmDialogProps } from "../dialog"
import { Button } from "../../generics/button/button"
import { Typography } from "../../generics/typographie/index"
import { Checkbox } from "../../generics/Checkbox/Checkbox"

export const ConfirmationDialogKey = "confirmationDialogKeys"

type Props = TmDialogProps & {
    cancelButtonText?: string
    confirmationButtonText?: string
    /**
     * A confirmationKey must be send for showing the Checkbox. The confirmationKeys must be unique and the new ones cand be added into the ConfirmationDialogKeys enum from @tm/models
     */
    confirmationKey?: string
    onCancel?(e: React.MouseEvent<HTMLButtonElement> | undefined): void
    onConfirm?(rememberSelectionId?: string[]): void
}

export const ConfirmationDialog = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ cancelButtonText, confirmationButtonText, children, confirmationKey, open, onConfirm, onCancel, ...rest }) => {
        const { translateText } = useLocalization()
        const [showDialog, setShowDialog] = useState(open ?? false)
        const [checked, setChecked] = useState(false)
        const { localStorageData, setLocalStorageData } = useLocalStorage<Record<string, boolean>>(ConfirmationDialogKey)
        const isSavedDecision = (confirmationKey?.length && localStorageData?.[confirmationKey]) || false

        useEffect(() => {
            setShowDialog(open ?? false)
            if (!open) {
                return
            }
            if (isSavedDecision) {
                setShowDialog(false)
                onConfirm?.()
            }
        }, [open])

        const handleConfirmClick = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
            if (checked && confirmationKey) {
                setLocalStorageData({ ...localStorageData, [confirmationKey]: true })
            }
            setShowDialog(false)
            onConfirm?.()
        }

        const handleCancelClick = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
            setShowDialog(false)
            onCancel?.(e)
        }

        return (
            <Dialog open={showDialog} skin="warning" position="top" fullWidth {...rest}>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="-3px">
                    <Box>{children}</Box>
                    <Box textAlign="center" display="flex" flexWrap="nowrap">
                        {cancelButtonText && (
                            <Button variant="contained" onClick={(e) => handleCancelClick(e)}>
                                {cancelButtonText}
                            </Button>
                        )}
                        {confirmationButtonText && (
                            <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={(e) => handleConfirmClick(e)}>
                                {confirmationButtonText}
                            </Button>
                        )}
                    </Box>
                </Box>
                {confirmationKey && (
                    <Box display="flex" alignItems="center">
                        <Checkbox checked={checked} onClick={() => setChecked((prev) => !prev)} />
                        <Typography fontSize="small">{translateText(13703)}</Typography>
                    </Box>
                )}
            </Dialog>
        )
    }
)
