import { useQuery, useQueryClient, useMutation, UseMutateAsyncFunction } from "react-query"
import { Container } from "@tm/nexus"
import { RegisteredModels, PartsContainer, Note } from "@tm/models"

const KEY_LOAD_ARTICLE_NOTE = "articleNote_load"

export const useLoadNotes = (articleIds: string[]): { articleNotes: Note[]; isLoading: boolean; error: any } => {
    const { data, isLoading, error } = useQuery<Note[]>(
        [KEY_LOAD_ARTICLE_NOTE, articleIds.join(",")],
        () => {
            const container = Container.getInstance(RegisteredModels.Article_Notes) as PartsContainer
            return container.action("loadArticleNoteByNoteIds")(articleIds).then()
        },
        { staleTime: 60 * 1000, enabled: !!articleIds.length }
    )

    return { articleNotes: data || [], isLoading, error }
}

export const useSaveNote = (): { saveNote: UseMutateAsyncFunction<void, unknown, Note>; saveNoteInProgress: boolean } => {
    const queryClient = useQueryClient()
    const mutation = useMutation<void, unknown, Note>(
        (articleNote: Note) => {
            const container = Container.getInstance(RegisteredModels.Article_Notes) as PartsContainer
            return container.action("saveArticleNote")(articleNote)
        },
        {
            onSuccess: () => {
                queryClient.resetQueries([KEY_LOAD_ARTICLE_NOTE])
            },
        }
    )

    return { saveNote: mutation.mutateAsync, saveNoteInProgress: mutation.isLoading }
}

export const useDeleteNote = (): { deleteNote: UseMutateAsyncFunction<void, unknown, string>; deleteNoteInProgress: boolean } => {
    const queryClient = useQueryClient()
    const mutation = useMutation(
        (articleNoteId: string) => {
            const container = Container.getInstance(RegisteredModels.Article_Notes) as PartsContainer
            return container.action("deleteArticleNote")(articleNoteId)
        },
        {
            onSuccess: () => {
                queryClient.resetQueries([KEY_LOAD_ARTICLE_NOTE])
            },
        }
    )

    return { deleteNote: mutation.mutateAsync, deleteNoteInProgress: mutation.isLoading }
}
