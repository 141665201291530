import { Children, memo, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { copyToClipboard } from "@tm/utils"
import { Box, LinearProgress, PaperProps, PaperTypeMap, Popover, PopoverProps, Stack, styled } from "@mui/material"
import { WhatsAppButton } from "./elements/WhatsAppButton"
import { EmailButton } from "./elements/EmailButton"
import { ShareButton } from "./elements/ShareButton"
import { DownloadButton } from "./elements/DownloadButton"
import { SharingLink } from "./elements/SharingLink"
import { Paper } from "../../generics/paper"
import { Button, ButtonProps } from "../../generics/button"
import { Icon } from "../../generics/Icons"
import { Typography } from "../../generics/typographie"
import { ShareButtonWrapper } from "./elements/ShareButtonWrapper"
import { AdditionalPopoverContent } from "./elements/AdditionalPopoverContent"

export type SharePopoverProps = {
    /**
     * This will be displayed as title in the popover
     */
    title?: string

    /**
     * The link that will be displayed and made available to share.
     */
    link: string | undefined

    /**
     * When no link is supplied this function will be called when the link is requested.
     */
    onRequestLink?(): void

    /**
     * Should be `true` when the link is currently being generated to show a loading animation.
     */
    isRequestingLink?: boolean

    /**
     * An optional error message that will be displayed when the link was requested but an error occured.
     */
    errorMessage?: string

    /**
     * Children could be either valid share buttons (access them like this for example `SharePopover.DownloadButton`)
     * or any other `ReactNode` which will be rendered inside the share popover.
     *
     * @see {@link ReactNode}
     */
    children: ReactNode

    /**
     * Props passed to the share button that opens the popover.
     */
    buttonProps?: ButtonProps

    /**
     * Props passed to the share popover.
     */
    popoverProps?: Omit<PopoverProps, "open" | "anchorEl">
}

const RowStack = styled(Stack)()
RowStack.defaultProps = {
    direction: "row",
    alignItems: "center",
    spacing: 2,
}

const LinkPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "hasError",
})<PaperProps & { hasError: boolean }>(({ theme, hasError }) => ({
    width: "600px",
    minWidth: "100%",
    position: "relative",
    border: `solid 1px ${hasError ? theme.palette.error.main : theme.palette.background.paper}`,
}))

const LinearLoader = styled(LinearProgress)({
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
})

const SharePopover = memo<SharePopoverProps>((props) => {
    const { title, link, onRequestLink, isRequestingLink, errorMessage, buttonProps, popoverProps } = props
    const { onClick: onPopoverOpen } = buttonProps ?? {}
    const { onClose: onPopoverClose } = popoverProps ?? {}
    const { translate, translateText } = useLocalization()

    const [isLinkCopied, setIsLinkCopied] = useState(false)
    const linkCopiedTimeoutRef = useRef(0)

    useEffect(() => {
        return () => {
            if (linkCopiedTimeoutRef.current) {
                window.clearTimeout(linkCopiedTimeoutRef.current)
            }
        }
    }, [])

    const handleGenerateLink = useCallback(async () => {
        if (!onRequestLink || isRequestingLink) {
            return
        }

        onRequestLink()
    }, [onRequestLink, isRequestingLink])

    const handleCopyLink = useCallback(async () => {
        if (!link) {
            return
        }

        try {
            await copyToClipboard(link)

            setIsLinkCopied(true)

            if (linkCopiedTimeoutRef.current) {
                window.clearTimeout(linkCopiedTimeoutRef.current)
            }

            linkCopiedTimeoutRef.current = window.setTimeout(() => setIsLinkCopied(false), 2000)
            // eslint-disable-next-line no-empty
        } catch {}
    }, [link])

    const copyButtonText = useMemo(() => {
        if (link) {
            if (isLinkCopied) {
                return translateText(13296)
            }

            return translateText(12785)
        }

        if (errorMessage) {
            return translateText(13297)
        }

        return translateText(13298)
    }, [link, isLinkCopied, errorMessage, translateText])

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget)
            onPopoverOpen?.(event)
        },
        [onPopoverOpen]
    )

    const handleClose = useCallback(
        (event, reason) => {
            setAnchorEl(null)
            onPopoverClose?.(event, reason)
        },
        [onPopoverClose]
    )

    return (
        <Box>
            <Button startIcon={<Icon name="share" />} {...buttonProps} onClick={handleClick}>
                {translate(12787)}
            </Button>
            <Popover
                {...popoverProps}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={
                    popoverProps?.anchorOrigin ?? {
                        vertical: "top",
                        horizontal: "center",
                    }
                }
                transformOrigin={
                    popoverProps?.transformOrigin ?? {
                        vertical: "bottom",
                        horizontal: "right",
                    }
                }
            >
                <Box p={2}>
                    {!!title && (
                        <RowStack>
                            <Typography variant="h4">{title}</Typography>
                        </RowStack>
                    )}
                    {validatedRender(props.children, isShareButtonWrapper)}
                    <LinkPaper hasError={!link && !!errorMessage}>
                        <RowStack>
                            <SharingLink link={link} errorMessage={errorMessage} flex={1} />
                            <Button
                                onClick={!link ? handleGenerateLink : handleCopyLink}
                                startIcon={link ? <Icon name="copy" /> : undefined}
                                disabled={isRequestingLink}
                            >
                                {copyButtonText}
                            </Button>
                        </RowStack>
                        {isRequestingLink && <LinearLoader />}
                    </LinkPaper>
                    {validatedRender(props.children, isAdditionalPopoverContent)}
                </Box>
            </Popover>
        </Box>
    )
})

type SharePopoverComponent = React.NamedExoticComponent<SharePopoverProps> & {
    ShareButtonWrapper: typeof ShareButtonWrapper
    AdditionalPopoverContent: typeof AdditionalPopoverContent
    WhatsAppButton: typeof WhatsAppButton
    EmailButton: typeof EmailButton
    DownloadButton: typeof DownloadButton
    ShareButton: typeof ShareButton
}

// Export all share buttons as properties of the SharePopover itself
const ExternalSharePopover = SharePopover as SharePopoverComponent
ExternalSharePopover.ShareButtonWrapper = ShareButtonWrapper
ExternalSharePopover.AdditionalPopoverContent = AdditionalPopoverContent
ExternalSharePopover.WhatsAppButton = WhatsAppButton
ExternalSharePopover.EmailButton = EmailButton
ExternalSharePopover.DownloadButton = DownloadButton
ExternalSharePopover.ShareButton = ShareButton

export { ExternalSharePopover as SharePopover }

function validatedRender(children: ReactNode, check: (node: ReactNode) => node is ReactElement) {
    return Children.map(children, (child) => {
        // Some validation to only render valid content passed as children of this component
        if (check(child)) {
            return child
        }
    })?.[0]
}

function isShareButtonWrapper(node: ReactNode): node is ReactElement {
    return !!node && typeof node === "object" && "type" in node && node.type === ShareButtonWrapper
}

function isAdditionalPopoverContent(node: ReactNode): node is ReactElement {
    return !!node && typeof node === "object" && "type" in node && node.type === AdditionalPopoverContent
}
