import { memo } from "react"
import { Box, Grid, Stack, styled } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ArticleNumbers } from "../../ArticleNumbers"
import { DefaultArticleErpContainer } from "../../ErpContainer"
import { CenterActions } from "../../Actions/CenterActions"
import { Image } from "../../Image"
import { Supplier } from "../../Supplier"
import { Transactions } from "../../Transactions"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../../../generics/Icons"
import { Divider } from "../../../../generics/divider"
import { CompactAttributes } from "../../Attributes/CompactAttributes"
import { RightActions } from "../../Actions/RightActions"
import { ExpandIconButton, GridItem } from "./ArticleItemComponents"
import { CompactAdditionalInformations } from "../../AdditionalInformations/CompactAdditionalInformations"
import { Tooltip } from "../../../../generics/tooltip"

const ActionsContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "hasNarrowRightActionsContainer",
})<{ hasNarrowRightActionsContainer?: boolean }>(({ hasNarrowRightActionsContainer }) => ({
    alignSelf: "stretch",
    flex: !hasNarrowRightActionsContainer ? "0 0 505px" : "0 0 240px",
    gap: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    padding: "8px 12px",
    ".availabilityContainer": {
        width: 104,
    },
    ".transactions": {
        ".addToBasketButton": {
            width: 70,
        },
        ".basketAmountField": {
            ".MuiInputBase-input": {
                paddingLeft: 4,
                width: 72,
            },
            "&[data-hasAmountinfo] .MuiInputBase-input": {
                width: 48,
            },
        },
        ".costEstimationButton .MuiButton-startIcon>svg.icon": {
            width: "auto",
        },
    },
}))

const ImageContainer = styled(Box)({
    margin: "5px",
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}) as typeof Grid

const SupplierSpacing = styled(Box)({
    width: "164px", // make sure to align the attribute number for all articles, I used a long supplier name as a reference
})

export const DefaultCompactArticleContentComponent = memo(() => {
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)
    const { hasNarrowRightActionsContainer } = useDefaultArticleItemState((x) => x.options)
    const { translateText } = useLocalization()

    const handleExpand = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, false)
        toggleExpand()
    }

    return (
        <Grid container direction="row" wrap="nowrap" alignItems="start">
            <ImageContainer>
                {/* Article Image */}
                <Grid item>
                    <Stack spacing={1} alignItems="flex-start">
                        <Image />
                    </Stack>
                </Grid>

                {/* Expand Icon */}
                <Grid item>
                    <Tooltip title={translateText(13844)}>
                        <ExpandIconButton onClick={handleExpand} hoverEffect disableRipple>
                            <Icon name="plus" />
                        </ExpandIconButton>
                    </Tooltip>
                </Grid>
            </ImageContainer>

            {/* Article Infos */}
            <GridItem item xs p={1} overflow="hidden">
                <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                        <Stack>
                            <Stack
                                justifyContent="start"
                                spacing={1}
                                alignItems="center"
                                divider={<Divider orientation="vertical" flexItem />}
                                display="flex"
                                direction="row"
                            >
                                <SupplierSpacing>
                                    <Supplier compactView />
                                </SupplierSpacing>
                                <ArticleNumbers />
                            </Stack>
                        </Stack>
                        <CenterActions />
                    </Stack>

                    <Stack spacing="4px" direction="row" key="vehicleAttribute" alignItems="center">
                        <CompactAttributes />
                        <CompactAdditionalInformations />
                    </Stack>
                </Stack>
            </GridItem>

            {/* Actions, ERP Infos */}
            <ActionsContainer hasNarrowRightActionsContainer={hasNarrowRightActionsContainer}>
                <DefaultArticleErpContainer />
                <Transactions />
                <RightActions />
            </ActionsContainer>
        </Grid>
    )
})
