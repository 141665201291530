import { Stack, styled } from "@mui/material"
import { CategoryType, TreeNode } from "@tm/models"
import { useEffect, useState } from "react"
import { TreeCategory } from "./TreeCategory"

const Overview = styled(Stack)(() => ({
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1em 1em 0.5em 1em",
    height: "394px",
}))

type Props = {
    initialTree: TreeNode[]
    hideSecondLevel?: boolean
    nodeVariant?: "fastClick" | "default"
    onNodeClick: (node: TreeNode) => void
    searchTreeType?: CategoryType
}

export function OverviewFlex(props: Props) {
    // in the searchtree is vertical space for 12 nodes where parent nodes count double for taking double the space
    const [chunkLimit, setChunkLimit] = useState(12)
    const [treeChunks, setTreeChunks] = useState<TreeNode[][]>([])

    const isWithinChunkLimit = (nodes?: Array<TreeNode>) => {
        if (nodes) {
            const totalNodesInChunk = nodes.reduce((total, node) => {
                return total + (node.childNodes ? node.childNodes.length + 2 : 0)
            }, 0)
            return totalNodesInChunk <= chunkLimit
        }
    }

    const chunkItems = () => {
        const tree = props.initialTree
        let chunks: Array<Array<TreeNode>> = []

        // ugly layout for specific industry catalog searchtree with fallback if searchtree is altered
        if (tree.length === 6) {
            if (props.searchTreeType === "vehicleParts") {
                chunks = [[tree[0], tree[1]], [tree[2]], [tree[3]], [tree[5], tree[4]]]
            } else {
                chunks = [[tree[0], tree[3]], [tree[1]], [tree[2]], [tree[4], tree[5]]]
            }
        } else {
            let i = 0
            while (i < tree.length) {
                // check if there is space left in chunk/column
                if (chunks.length > 0 && isWithinChunkLimit([...chunks.last()!, tree[i]])) {
                    chunks.last()?.push(tree[i])
                } else {
                    chunks.push([tree[i]])
                }
                i += 1
            }

            // have always 4 chunks/columns
            if (chunks.length > 4) {
                setChunkLimit((prev) => prev + 1)
                chunks = []
            }
        }

        return chunks
    }

    useEffect(() => {
        setTreeChunks(chunkItems())
    }, [props, chunkLimit])

    return (
        <Overview>
            {treeChunks.map((chunk) => {
                return (
                    <Stack key={`chunkKey${chunk.first()?.id}`}>
                        {chunk.map((node, index) => (
                            <TreeCategory
                                key={`nodeKey_${node.id}`}
                                category={node}
                                onNodeClick={props.onNodeClick}
                                hideSecondLevel={props.hideSecondLevel}
                                nodeVariant={props.nodeVariant}
                                anonymousProps={{ isAnonymous: true, isFirstInChunk: index === 0 }}
                            />
                        ))}
                    </Stack>
                )
            })}
        </Overview>
    )
}
