import { useLocalization } from "@tm/localization"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import BonusPoint from "./BonusPoint"
import { BonusSystemImage } from "./BonusSystemImage"

const WholesalerBonusPoints = 10

export function BonusSystem() {
    const bonus = useDefaultArticleItemState((x) => x.displayArticle.bonus)
    const { translateText } = useLocalization()

    if (!bonus) {
        return null
    }

    if (bonus.id === WholesalerBonusPoints) {
        return <BonusPoint bonus={bonus} tooltipLabel={translateText(13113)} label={translateText(1826)} />
    }

    return <BonusSystemImage bonus={bonus} />
}
