import { RegisteredModels, ShowDefaultWarehouseRequest, ShowDefaultWarehouseResponse } from "@tm/models"
import { Container } from "@tm/nexus"
import { useCallback } from "react"
import { useQuery } from "react-query"

const KEY = "erp_showDefaultOrderWarehouse"
export function useDefaultOrderWarehouse(request: ShowDefaultWarehouseRequest, enabled?: boolean) {
    const {
        data: warehouseData,
        isLoading: warehouseDataIsLoading,
        refetch,
    } = useQuery<ShowDefaultWarehouseResponse>(
        [KEY, request],
        () => {
            return Container.getInstance<ShowDefaultWarehouseResponse>(RegisteredModels.ERP_ShowDefaultWarehouse)
                .subscribe(request)
                .load()
                .catch(() => ({ hasErrors: true }))
        },
        {
            enabled,
            staleTime: Infinity,
        }
    )

    const refetchWarehouseData = useCallback(async () => {
        await refetch()
    }, [refetch])

    return { warehouseData, warehouseDataIsLoading, refetchWarehouseData }
}
