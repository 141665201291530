import { Article, BonusSystem, BonusSystemWithPoints, BonusSystemWithoutPoints } from "@tm/models"

export function getArticleBonusSystemsWithoutPoints(article: Article): Array<BonusSystemWithoutPoints> {
    return article.availableBonusSystems?.filter((x) => x.value === undefined) ?? []
}

function withPoints(x: BonusSystem): x is BonusSystemWithPoints {
    return x.value !== undefined
}

export function getArticleBonusSystemsWithPoints(article: Article): Array<BonusSystemWithPoints> {
    return article.availableBonusSystems?.filter(withPoints) ?? []
}
