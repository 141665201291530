import { Box, styled, Typography } from "@mui/material"
import { BonusSystemWithPoints } from "@tm/models"
import { Tooltip } from "../../generics/tooltip"

type Props = {
    bonusSystem: BonusSystemWithPoints
    label?: string
    /** @default "two-line-label" */
    variant?: "two-line-label" | "single-line-label"
}

const BonusPointsBox = styled(Box)(({ theme }) => ({
    border: "solid 1px #085cf0",
    borderRadius: theme.radius?.default,
    padding: theme.spacing(0.25, 1),
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: "#085cf0",
}))

const BonusPointsText = styled(Typography)(({ theme }) => ({
    color: theme.palette.getContrastText("#085cf0"),
    minWidth: "3em",
    lineHeight: 1,
    fontSize: theme.font?.textSize.s,
    small: {
        fontSize: theme.font?.textSize.xs,
    },
}))

export const BonusPoints: React.FC<Props> = ({ bonusSystem, label, variant }) => {
    function renderTwoLineLabel() {
        return (
            <>
                <br />
                <small>{label}</small>
            </>
        )
    }

    function renderSingleLineLabel() {
        return ` ${label}`
    }

    return (
        <Tooltip title={bonusSystem.name} noDelay>
            <BonusPointsBox>
                <BonusPointsText>
                    <strong>{bonusSystem.value.toLocaleString()}</strong>
                    {label !== undefined && variant === "single-line-label" ? renderSingleLineLabel() : renderTwoLineLabel()}
                </BonusPointsText>
            </BonusPointsBox>
        </Tooltip>
    )
}
