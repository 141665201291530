import { RegistrationNoType, VehicleType } from "@tm/models"
import { isVin } from "./vin"

type RegistrationNumberFields = {
    placeholder?: number // vehicle search field
    exampleQuery?: string // vehicle search field
    tooltip?: number // vehicle search field
    label?: number // vehicle info (fallback), vehicle details, cost estimation
    labelShort?: number // vehicle info
}

export function getRegistrationNumberTypeTextIds(regNoType: RegistrationNoType | undefined, vehicleType: VehicleType): RegistrationNumberFields {
    switch (regNoType) {
        case RegistrationNoType.KennzeichenNiederlande:
        case RegistrationNoType.KennzeichenNiederlandeCarat:
        case RegistrationNoType.KennzeichenNiederlandeSchwenker:
            return { placeholder: 1726, exampleQuery: "PPXF69", tooltip: 21 } // No label to not show input field on vehicle details (because it's already existing as license plate field)

        case RegistrationNoType.LicencePlateDK:
            return { placeholder: 1726, exampleQuery: "DG20236", tooltip: 21 } // No label to not show input field on vehicle details (because it's already existing as license plate field)

        case RegistrationNoType.NationalcodeAustria:
            return { placeholder: 1219, exampleQuery: "039557", tooltip: 1218, label: 1218 }

        case RegistrationNoType.NationalcodeFrankreich:
        case RegistrationNoType.NationalcodeFrankreichHerthBuss:
        case RegistrationNoType.NationalCodeFrankreichCarat:
            return { placeholder: 1219, exampleQuery: "000AC", tooltip: 1218, label: 1218 }

        case RegistrationNoType.Kba:
            return {
                placeholder: 1113,
                exampleQuery: vehicleType === VehicleType.Motorcycle ? "0005 ASN" : "0588 599",
                tooltip: 103,
                label: 103,
                labelShort: 1490,
            }

        case RegistrationNoType.TypenscheinDehrendinger:
        case RegistrationNoType.TypenscheinSchweizCarat:
        case RegistrationNoType.TypenscheinSchweizEsa:
        case RegistrationNoType.TypenscheinSchweizEurotax:
        case RegistrationNoType.TypenscheinSchweizHoststettler:
        case RegistrationNoType.TypeApprovalNumberSwitzerlandRhiag:
        case RegistrationNoType.SchweizTypenscheinAtu:
            return { placeholder: 1790, exampleQuery: "1BB345", tooltip: 1730, label: 1730 }

        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Eurotax:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Hostettler:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_ESA:
            return { placeholder: 1726, exampleQuery: "ZH660161", tooltip: 1730, label: 1730 }
        case RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Hostettler:
        case RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Rhiag:
        case RegistrationNoType.LicensePlateSwitzerlandECOC_AutoIDat_Esa:
        case RegistrationNoType.LicensePlateSwitzerland_RhiagCH:
            return { placeholder: 13512, exampleQuery: "ZH660161", tooltip: 1730, label: 1730 }
        case RegistrationNoType.KennzeichenFinnlandHlGroup:
            return { placeholder: 1726, exampleQuery: "EVG197", tooltip: 21, label: 21 }

        default:
            return {}
    }
}

export function getVehicleRegistrationNoLabelTextIds(regNoType?: RegistrationNoType): { label?: number; labelShort?: number } | undefined {
    const textId = getRegistrationNumberTypeTextIds(regNoType, VehicleType.PassengerCar)
    if (!textId.label && !textId.labelShort) {
        return undefined
    }

    return { label: textId.label, labelShort: textId.labelShort }
}

export function isVinType(type: RegistrationNoType | undefined, query?: string): boolean {
    if (type === undefined) {
        return false
    }

    if (query && (type === RegistrationNoType.VLPortugalCreateBusiness || type === RegistrationNoType.VrmKennzeichenSpanienDGT)) {
        return isVin(query) // assume the query is a VIN
    }

    return type === RegistrationNoType.DatVin || type === RegistrationNoType.VrmVINSpanienInforauto
}

export function isLicensePlateType(type: RegistrationNoType | undefined, query?: string): boolean {
    if (type === undefined) {
        return false
    }

    if (query && (type === RegistrationNoType.VLPortugalCreateBusiness || type === RegistrationNoType.VrmKennzeichenSpanienDGT)) {
        return !isVin(query) // assume the query is a license plate
    }

    return (
        type === RegistrationNoType.CartellIrlandKennzeichenWsvc ||
        type === RegistrationNoType.ItalienEdidomusWsvc ||
        type === RegistrationNoType.KennzeichenPortugal ||
        type === RegistrationNoType.KoivunenCatDataWsvc ||
        type === RegistrationNoType.LicensePlateBrazilDriveRight ||
        type === RegistrationNoType.LicensePlateDenmarkDriveRight ||
        type === RegistrationNoType.LicensePlateDriveRight ||
        type === RegistrationNoType.LicensePlateEireDriveRight ||
        type === RegistrationNoType.LicensePlateEstoniaAlm ||
        type === RegistrationNoType.LicensePlateFinlandDriveRight ||
        type === RegistrationNoType.LicensePlateFranceDriveRight ||
        type === RegistrationNoType.LicensePlateItalyDriveRight ||
        type === RegistrationNoType.LicensePlateItalyRhiag ||
        type === RegistrationNoType.LicensePlateNetherlandsDriveRight ||
        type === RegistrationNoType.LicensePlateNewZealandDriveRight ||
        type === RegistrationNoType.LicensePlateNorwayDriveRight ||
        type === RegistrationNoType.LicensePlatePortugalDriveRight ||
        type === RegistrationNoType.LicensePlateSpainDriveRight ||
        type === RegistrationNoType.LicensePlateSwedenDriveRight ||
        type === RegistrationNoType.LicensePlateSwitzerlandAutoIDat_ESA ||
        type === RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Eurotax ||
        type === RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Hostettler ||
        type === RegistrationNoType.LicensePlateSwitzerlandDriveRight ||
        type === RegistrationNoType.LicensePlateUKDriveRight ||
        type === RegistrationNoType.LicensePlateUSDriveRight ||
        type === RegistrationNoType.VINFranceInforauto ||
        type === RegistrationNoType.VrmKennzeichenSpanienDGT ||
        type === RegistrationNoType.VrmLicensePlateHaynesProIt
    )
}

export function isRegNoType(type: RegistrationNoType | undefined): boolean {
    if (type === undefined) {
        return false
    }

    return (
        type === RegistrationNoType.NationalcodeAustria ||
        type === RegistrationNoType.SchweizTypenscheinAtu ||
        type === RegistrationNoType.TypenscheinDehrendinger ||
        type === RegistrationNoType.TypenscheinSchweizCarat ||
        type === RegistrationNoType.TypenscheinSchweizEsa ||
        type === RegistrationNoType.TypenscheinSchweizEurotax ||
        type === RegistrationNoType.TypenscheinSchweizHoststettler
    )
}

export function useRegNoCaption(type?: RegistrationNoType): number {
    switch (type) {
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_ESA:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Eurotax:
        case RegistrationNoType.LicensePlateSwitzerlandAutoIDat_Hostettler:
        case RegistrationNoType.SchweizTypenscheinAtu:
        case RegistrationNoType.TypenscheinDehrendinger:
        case RegistrationNoType.TypenscheinSchweizCarat:
        case RegistrationNoType.TypenscheinSchweizEsa:
        case RegistrationNoType.TypenscheinSchweizEurotax:
        case RegistrationNoType.TypenscheinSchweizHoststettler:
            return 1730 // "Typenscheinnr."
        case RegistrationNoType.DatVin:
            return 103 // "KBA Nummer"
        default:
            return 0
    }
}
