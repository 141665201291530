import { Checkbox, Stack } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { GraduatedPrices } from "../GraduatedPrices"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { ExpandedActionsContent } from "./ExpandedActionsContent"
import { CompactActionsContent } from "./CompactActionsContent"

export function RightActions() {
    const articleListExpanded = useDefaultArticleItemState((x) => x.expanded)
    const article = useDefaultArticleItemState((x) => x.article)
    const quantity = useDefaultArticleItemState((x) => x.quantity)
    const { isArticleSelected, toggleSelectedArticle, maximumPartsToCompare, selectedArticles } = useDefaultArticleItemState((x) => x.selection)

    const handleArticleCheckBoxEvent = () => {
        const maxParts = maximumPartsToCompare || 25
        const isSelected = isArticleSelected(article)

        if (selectedArticles?.length >= maxParts && !isSelected) {
            return
        }

        toggleSelectedArticle({
            ...article,
            quantity,
        })

        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleCheckbox, !isSelected)
    }

    return (
        <Stack direction="row" justifyContent="end" spacing={1}>
            <GraduatedPrices />
            {articleListExpanded ? <ExpandedActionsContent /> : <CompactActionsContent />}
            <Checkbox size="small" sx={{ padding: 0 }} checked={isArticleSelected(article)} onChange={handleArticleCheckBoxEvent} />
        </Stack>
    )
}
