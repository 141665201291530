import { MenuItem, Stack, Typography } from "@mui/material"
import { HistoryVehicle, UserContext } from "@tm/models"
import { getShortCountryCodeByUserContext, useRegNoCaption } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Separator } from "../../generics/separator"
import { LicensePlate } from "../LicensePlate"

type HistoryItemProps = {
    vehicle: HistoryVehicle
    onClick(vehicleId: string): void
    userContext?: UserContext
}

export default function HistoryItem(props: HistoryItemProps) {
    const { vehicle, onClick, userContext } = props
    const { translateText } = useLocalization()
    const regNoCaption = translateText(useRegNoCaption(vehicle.registrationTypeId) || 103)
    const countryCode = getShortCountryCodeByUserContext(userContext)

    return (
        <MenuItem value={vehicle.id} onClick={() => onClick(vehicle.id)}>
            <Stack spacing={0.5} direction="row">
                <Typography variant="body2">{vehicle.description}</Typography>
                {vehicle.plateId && (
                    <>
                        <Separator />
                        <LicensePlate countryCode={countryCode} value={vehicle.plateId} />
                    </>
                )}
                {vehicle.registrationNumber && vehicle.registrationNumber !== vehicle.plateId && (
                    <>
                        <Separator />
                        <Typography variant="body2" fontWeight="bold">
                            {`${regNoCaption}:`}
                        </Typography>
                        <Typography variant="body2">{vehicle.registrationNumber}</Typography>
                    </>
                )}
                {vehicle.vin && (
                    <>
                        <Separator />
                        <Typography variant="body2" fontWeight="bold">
                            {translateText(13557)}
                        </Typography>
                        <Typography variant="body2">{vehicle.vin}</Typography>
                    </>
                )}
            </Stack>
        </MenuItem>
    )
}
