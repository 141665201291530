import { memo, useMemo } from "react"
import { AddToBasket } from "./AddToBasket"
import { useArticleItemState } from "../ContextProviderStates"

export const AddOeToBasket = memo(() => {
    const quantity = useArticleItemState((x) => x.quantity)
    const articleErpInfo = useArticleItemState((x) => x.articleErpInfos?.default)
    const division = useMemo(() => (articleErpInfo?.state === "success" ? articleErpInfo.response.quantity?.division : 1) || 1, [articleErpInfo])
    const basketQuantity = useArticleItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleChangeQuantity = useArticleItemState((x) => x.handleChangeQuantity)
    const handleRemoveFromBasket = useArticleItemState((x) => x.handleRemoveFromBasket)
    const handleAddToBasket = useArticleItemState((x) => x.handleAddToBasket)

    return (
        <AddToBasket
            onAddToBasket={handleAddToBasket}
            handleChangeQuantity={handleChangeQuantity}
            onRemoveFromBasket={handleRemoveFromBasket}
            originalQuantity={quantity}
            basketQuantity={basketQuantity}
            division={division}
        />
    )
})
