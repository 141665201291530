import { useCallback, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { styled } from "@mui/material"
import { Button, ButtonProps } from "../../../generics/button"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"

type CostEstimationProps = {
    highlightCostEstimationButton?: boolean
    disabled?: boolean
    onAddToCostEstimation(): void
}

const CostEstimationButtonComponent = styled(Button)(({ theme }) => ({
    ".MuiButton-startIcon>svg.icon-voucher-kva": {
        width: 30,
    },
    ".MuiButton-startIcon>svg.icon-voucher-kva-international": {
        width: 30,
    },
    ...(theme.name === "create-business" && {
        ".MuiButton-startIcon>svg.icon-voucher-kva": {
            width: 36,
        },
        ".MuiButton-startIcon>svg.icon-voucher-kva-international": {
            width: 36,
        },
    }),
}))

export function CostEstimationButton(props: CostEstimationProps) {
    const { onAddToCostEstimation, highlightCostEstimationButton, disabled } = props
    const { translateText, languageId } = useLocalization()

    const handleAddToCostEstimation = useCallback(() => onAddToCostEstimation(), [onAddToCostEstimation])

    const costEstimationButtonProps = useMemo<Partial<ButtonProps>>(
        () => ({
            ...(!!highlightCostEstimationButton && {
                color: "highlight",
                sx: { width: 60 },
            }),
        }),
        [highlightCostEstimationButton]
    )

    return (
        <Tooltip title={translateText(917)}>
            <CostEstimationButtonComponent
                className="costEstimationButton"
                disabled={disabled}
                onClick={handleAddToCostEstimation}
                variant="bordered"
                startIcon={<Icon name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} />}
                {...costEstimationButtonProps}
            />
        </Tooltip>
    )
}
