import { Stack, styled } from "@mui/material"
import { ErpIcon, ErpInformation } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { SpecialIcon } from "./ErpContainer/SpecialIcon"
import { ErpIconPosition, useErpSpecialIcons } from "../../hooks/useErpSpecialIcons"

export const ErpIconsWrapper = styled(Stack)({
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "4px",

    "&:empty": {
        display: "none",
    },
}) as typeof Stack

type Props = {
    erpInfo?: ErpInformation
    position: ErpIconPosition
}

export function ErpIcons({ position, erpInfo }: Props) {
    const { translateText } = useLocalization()
    const erpSpecialIcons = useErpSpecialIcons(position)

    const specialIcons: ErpIcon[] = []

    if (erpInfo?.isSellOffArticle) {
        specialIcons.push({
            topmotiveId: "sale-off",
            useIconFromUrl: false,
            url: "",
            description: translateText(13786),
        })
    }

    if (erpInfo?.isNonReturnable) {
        specialIcons.push({
            topmotiveId: "no-return",
            useIconFromUrl: false,
            url: "",
            description: translateText(13790),
        })
    }

    specialIcons.push(...erpSpecialIcons)

    return (
        <>
            {specialIcons.map((x) => (
                <SpecialIcon key={x.useIconFromUrl ? x.url : x.topmotiveId} icon={x} />
            ))}
        </>
    )
}
