import { useCallback, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Grid, Stack, styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../../generics/typographie"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"

const ClickItem = styled(Box)(({ theme }) => ({
    display: "contents",
    cursor: "pointer",
    "> *": {
        padding: "2px 4px",
        ":first-child": {
            paddingLeft: 8,
            marginLeft: -8,
        },
        ":last-child": {
            paddingRight: 8,
            marginRight: -8,
        },
    },
    "&:hover > *": {
        background: theme.palette.grey[400],
    },
}))

export function GraduatedPrices() {
    const { translateText, currency } = useLocalization()

    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const handleAddToBasket = useDefaultArticleItemState((x) => x.handleAddToBasket)

    const graduatedPrices = useMemo(() => {
        const erpInfo = articleErpInfos?.default?.state === "success" ? articleErpInfos.default.response : undefined
        if (!erpInfo?.graduatedPrices?.length) {
            return undefined
        }
        return erpInfo.graduatedPrices.filter((x) => !!x.priceUnit)
    }, [articleErpInfos?.default])

    const handleClick = useCallback(
        (amount?: number) => {
            if (!amount) {
                return
            }
            handleAddToBasket(amount)
        },
        [handleAddToBasket]
    )

    const title = useMemo(
        () => (
            <Stack gap={0.5}>
                <Typography variant="label">{translateText(705)}</Typography>
                <Grid display="grid" gridTemplateColumns="auto auto">
                    {graduatedPrices?.map(({ currencySymbol, priceUnit, value }) => (
                        <ClickItem key={`${value}`} onClick={() => handleClick(priceUnit)}>
                            <Typography textAlign="right">{value && `${translateText(707)} ${priceUnit} ${translateText(708)}:`}</Typography>
                            <Typography textAlign="right" fontWeight={600}>
                                {currency(value, currencySymbol)}
                            </Typography>
                        </ClickItem>
                    ))}
                </Grid>
            </Stack>
        ),
        [currency, graduatedPrices, handleClick, translateText]
    )

    if (!graduatedPrices) {
        return null
    }

    return (
        <Tooltip title={title} variant="light">
            <Icon name="staffelpreis" />
        </Tooltip>
    )
}
