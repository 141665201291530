import { forwardRef, PropsWithChildren, ReactElement, Ref } from "react"
import { Box, Dialog, DialogProps, dialogClasses, styled, Slide } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import { Icon } from "../../generics/Icons"
import { Button } from "../../generics/button/button"

type Props = Omit<DialogProps, "onClose"> & {
    onOutsideClick?(): void
    percentWidth?: number
    hideCloseButton?: boolean
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>
    },
    ref: Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />
})

const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "percentWidth" && prop !== "hideCloseButton" && prop !== "onOutsideClick",
})<Pick<Props, "percentWidth">>(({ percentWidth }) => ({
    [`.${dialogClasses.scrollPaper}`]: {
        height: "100%",
        width: `${percentWidth || 80}%`,
        marginLeft: "auto",
        position: "relative",
    },
    [`.${dialogClasses.paper}`]: {
        width: "100%",
        height: "100%",
        maxHeight: "none",
        maxWidth: "none",
        margin: 0,
        position: "static",
    },
}))

export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
    return (
        <StyledDialog ref={ref} {...props} onClose={props.onOutsideClick} TransitionComponent={Transition}>
            {!props.hideCloseButton && (
                <Button
                    onClick={props.onOutsideClick}
                    color="primary"
                    size="large"
                    style={{ position: "absolute", top: "calc(50% - 17px)", left: "-17px", zIndex: "1", minWidth: "auto" }}
                >
                    <Icon name="close" />
                </Button>
            )}
            <Box p={2} height="100%">
                {props.children}
            </Box>
        </StyledDialog>
    )
})
